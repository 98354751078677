import request from "@/core/services/axios";

//考试列表
export function getExamListByTimeRange(obj) {
  return request({
    url: "/resource/exampaperplan/get/examList",
    method: "post",
    data: obj,
  });
}
//错题生成试卷
export function generatePaperError(obj) {
  return request({
    url: "/resource/exampaperplan/generate/wrongQuestionPlan",
    method: "post",
    data: obj,
  });
}
//薄弱项生成试卷
export function generatePaperPoint(obj) {
  return request({
    url: "/resource/exampaperplan/generate/weakKnowledgePlan",
    method: "post",
    data: obj,
  });
}
//知识点
export function knowledgeList(obj) {
  return request({
    url: "/resource/exampaperplan/get/knowledgeList",
    method: "post",
    data: obj,
  });
}
