<!-- 学情组卷 -->
<template>
  <div class="learning-paper">
    <div class="tab">
      <div
        v-for="(item, key) in pageTab"
        :key="key"
        class="tab-item"
        :class="{ on: item.value == currentTab }"
        @click="changePageTab(item.value)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="content">
      <div v-if="currentTab == 1" class="error-training">
        <el-form ref="errorForm" :model="errorTrainingForm" label-width="100px">
          <el-form-item label="年级：" prop="gradeId">
            <div class="form-item">
              <template v-if="gradeOptions.length > 0">
                <div
                  v-for="(item, key) in gradeOptions"
                  :key="key"
                  class="list-item"
                  :class="{ on: item.id == errorTrainingForm.gradeId }"
                  @click="getClassByGradeId(item)"
                >
                  {{ item.name }}
                </div>
              </template>
              <div v-else>此科目下无学学情数据</div>
            </div>
          </el-form-item>
          <el-form-item v-if="classOptions.length > 0" label="班级：">
            <div class="form-item">
              <div
                v-for="(item, key) in classOptions"
                :key="key"
                class="list-item"
                :class="{ on: item.select == true }"
                @click="setClassNum(item)"
              >
                {{ item.classNum + "班" }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="选择考试范围：">
            <div class="form-btn" @click="showExamScopeDialog">
              点击选择考试范围
              <i class="el-icon-d-arrow-right"></i>
            </div>
          </el-form-item>
          <el-form-item label="知识点得分率：">
            <div class="form-item">
              <div
                v-for="(rate, key) in 5"
                :key="key"
                class="list-item"
                :class="{ on: rate == errorScoreRate }"
                @click="setScoreRate(rate)"
              >
                {{ (rate - 1) * 20 + "%-" + rate * 20 + "%" }}
              </div>
              <div
                class="list-item"
                :class="{ on: errorScoreRate == 0 }"
                @click="customScoreRate"
              >
                自定义
              </div>
              <div v-if="errorScoreRate == 0" class="score-rate">
                <el-input
                  v-model="errorRateMin"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @change="setMinRate"
                ></el-input
                >%
                <span class="line"></span>
                <el-input
                  v-model="errorRateMax"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @change="setMaxRate"
                ></el-input
                >%
              </div>
            </div>
          </el-form-item>
          <el-form-item label="得分率排序：">
            <div class="form-item">
              <div
                class="list-item"
                :class="{ on: errorTrainingForm.scoreRateOrder == 0 }"
                @click="setScoreRateOrder(0)"
              >
                从低到高排序
              </div>
              <div
                class="list-item"
                :class="{ on: errorTrainingForm.scoreRateOrder == 1 }"
                @click="setScoreRateOrder(1)"
              >
                从高到低排序
              </div>
            </div>
          </el-form-item>
          <el-form-item label="试题数量：" prop="questionCount">
            <div class="form-item">
              <el-input v-model="errorTrainingForm.questionCount"></el-input>题
            </div>
          </el-form-item>
          <el-form-item label="训练模式：">
            <div class="form-item">
              <div
                class="list-item"
                :class="{ on: errorTrainingForm.isNeedOriginal == 1 }"
                @click="setTrainingMode(1)"
              >
                错题重做
              </div>
              <div
                class="list-item"
                :class="{ on: errorTrainingForm.isNeedOriginal == 0 }"
                @click="setTrainingMode(0)"
              >
                错题拓展
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item
            v-if="errorTrainingForm.isNeedOriginal == 0"
            label="变式题比例："
          >
            <div class="form-item">
              <el-radio-group v-model="errorTrainingForm.proportion">
                <el-radio v-for="(rate, key) in 5" :key="key" :label="rate">
                  {{ "1:" + rate }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <div v-if="currentTab == 2" class="weak-training">
        <el-form ref="weakForm" :model="weakTrainingForm" label-width="100px">
          <el-form-item label="年级：">
            <div class="form-item">
              <template v-if="gradeOptions.length > 0">
                <div
                  v-for="(item, key) in gradeOptions"
                  :key="key"
                  class="list-item"
                  :class="{ on: item.id == weakTrainingForm.gradeId }"
                  @click="getClassByGradeId(item)"
                >
                  {{ item.name }}
                </div>
              </template>

              <div v-else>此科目下无学学情数据</div>
            </div>
          </el-form-item>
          <el-form-item v-if="classOptions.length > 0" label="班级：">
            <div class="form-item">
              <div
                v-for="(item, key) in classOptions"
                :key="key"
                class="list-item"
                :class="{ on: item.select == true }"
                @click="setClassNum(item)"
              >
                {{ item.classNum + "班" }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="统计时间段：">
            <div class="content-item">
              <div
                v-for="(item, key) in optionsTime"
                :key="key"
                class="list-item"
                :class="{ on: item.value === weakTrainingForm.timeRange }"
                @click="setTimeRange(item)"
              >
                {{ item.label }}
              </div>
              <div
                class="list-item"
                :class="{ on: weakTrainingForm.timeRange == 99 }"
                @click="setTimeRange(99)"
              >
                自定义
              </div>
              <el-date-picker
                v-if="weakTrainingForm.timeRange == 99"
                v-model="weakDateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :unlink-panels="true"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="知识点得分率：">
            <div class="form-item">
              <div
                v-for="(rate, key) in 5"
                :key="key"
                class="list-item"
                :class="{ on: rate == weakScoreRate }"
                @click="setScoreRate(rate)"
              >
                {{ (rate - 1) * 20 + "%-" + rate * 20 + "%" }}
              </div>
              <div
                class="list-item"
                :class="{ on: weakScoreRate == 0 }"
                @click="customScoreRate"
              >
                自定义
              </div>
              <div v-if="weakScoreRate == 0" class="score-rate">
                <el-input
                  v-model="weakRateMin"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @change="setMinRate"
                ></el-input
                >%
                <span class="line"></span>
                <el-input
                  v-model="weakRateMax"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @change="setMaxRate"
                ></el-input
                >%
              </div>
            </div>
          </el-form-item>
          <el-form-item label="得分率排序：">
            <div class="form-item">
              <div
                class="list-item"
                :class="{ on: weakTrainingForm.scoreRateOrder == 0 }"
                @click="setScoreRateOrder(0)"
              >
                从低到高排序
              </div>
              <div
                class="list-item"
                :class="{ on: weakTrainingForm.scoreRateOrder == 1 }"
                @click="setScoreRateOrder(1)"
              >
                从高到低排序
              </div>
            </div>
          </el-form-item>
          <el-form-item label="选择知识点：">
            <div v-if="allPointList.length > 0" class="label">
              (共{{ allPointList.length }}个)
            </div>
            <div v-loading="pointLoading" class="point-list">
              <div class="points">
                <div
                  v-for="(item, key) in pointList"
                  :key="key"
                  class="point-item"
                >
                  <div class="point-name">
                    <span>{{ item.name }}</span>
                    <span>{{ item.scoreRate | setNumber }}</span>
                  </div>
                  <div class="point-count">
                    <span>题库现有</span>
                    <span class="plr8">{{ item.count }}</span>
                    <span>题，最多</span>
                    <el-input
                      v-model="item.max"
                      @input="resetMaxCount(item, key)"
                    ></el-input>
                    <span>题</span>
                  </div>
                </div>
              </div>

              <div class="point-add">
                <div class="btn" @click="addPoint">
                  添加<i class="el-icon-plus"></i>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="训练模式：">
            <div class="form-item">
              <div
                class="list-item"
                :class="{ on: weakTrainingForm.isNeedOriginal == 1 }"
                @click="setTrainingMode(1)"
              >
                错题重做
              </div>
              <div
                class="list-item"
                :class="{ on: weakTrainingForm.isNeedOriginal == 0 }"
                @click="setTrainingMode(0)"
              >
                错题拓展
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item
            v-if="weakTrainingForm.isNeedOriginal == 0"
            label="变式题比例："
          >
            <div class="form-item">
              <el-radio-group v-model="weakTrainingForm.proportion">
                <el-radio v-for="(rate, key) in 5" :key="key" :label="rate">
                  {{ "1:" + rate }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
    <div class="footer">
      <div class="create-paper" @click="createPaper">生成试卷</div>
    </div>
    <!-- 选择考试范围弹窗 -->
    <el-dialog
      class="dialog-exam-scope"
      title="选择考试范围"
      :visible.sync="examScopeDialogVisible"
      width="816px"
      :close-on-click-modal="false"
      @close="closeExamScopeDialog"
    >
      <div class="dialog-content">
        <el-form :model="errorTrainingForm" label-width="100px">
          <el-form-item label="统计时间段：">
            <div class="content-item">
              <div
                v-for="(item, key) in optionsTime"
                :key="key"
                class="list-item"
                :class="{ on: item.value === errorTrainingForm.timeRange }"
                @click="setTimeRange(item)"
              >
                {{ item.label }}
              </div>
              <div
                class="list-item"
                :class="{ on: errorTrainingForm.timeRange == 99 }"
                @click="setTimeRange(99)"
              >
                自定义
              </div>
              <el-date-picker
                v-if="errorTrainingForm.timeRange == 99"
                v-model="errorDateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :unlink-panels="true"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="考试类型：">
            <div class="content-item exam-type">
              <div class="btns">
                <div class="btn-item" @click="examTypeBatchSelection(0)">
                  全选
                </div>
                |
                <div class="btn-item" @click="examTypeBatchSelection(1)">
                  反选
                </div>
                |
                <div class="btn-item" @click="examTypeBatchSelection(2)">
                  取消
                </div>
              </div>
              <div class="lists">
                <div
                  v-for="(item, key) in examTypeOptions"
                  :key="key"
                  class="list-item"
                  :class="{ on: item.select == true }"
                  @click="setExamType(item)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="考试场次：">
            <div :loading="examPaperLoading" class="exam-list-item">
              <div class="list">
                <el-table
                  ref="multipleTable"
                  :data="examPaperList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column
                    type="selection"
                    min-width="10%"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column label="序号" min-width="10%" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.index }}
                    </template>
                  </el-table-column>
                  <el-table-column label="日期" min-width="20%" align="center">
                    <template slot-scope="scope"
                      >{{ scope.row.examDate }}
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" min-width="20%" align="center">
                    <template slot-scope="scope">
                      {{ getLabelByValue(scope.row.type, examTypeOptions) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="考试名称"
                    min-width="40%"
                    align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.examName }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="exam-tips">只有生成统计的考试，才会显示在此处哟</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="saveExamScope">下一步</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getGradeList,
  classlistbygrade,
} from "@/core/api/school/schoolgradeclass";
import { examTypeOptions, optionsTime } from "@/core/util/constdata";
import {
  getExamListByTimeRange,
  generatePaperError,
  generatePaperPoint,
  knowledgeList,
} from "@/core/api/resource/learningPaper";
import { getStore } from "@/core/util/store";
const pageTab = [
  {
    label: "错题训练",
    value: 1,
  },
  {
    label: "薄弱项训练",
    value: 2,
  },
];
export default {
  name: "LearningPaper",
  filters: {
    setNumber(val) {
      if (!val) {
        return "0.00 %";
      }
      let number = (val * 100).toFixed(2);
      return number + " %";
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     //从试卷预览页面跳转过来
  //     if (from.name == "PaperPreview") {
  //       let storage = getStore({ name: "createPaper" });
  //       vm.currentTab = storage.tab_id;
  //       if (storage.tab_id == 2) {
  //         vm.weakTrainingForm = storage.paperObj;
  //         vm.weakDateRange = storage.paperObj.weakDateRange;
  //         //得分率
  //         vm.weakRateMin = storage.paperObj.weakRateMin;
  //         vm.weakRateMax = storage.paperObj.weakRateMax;
  //         vm.weakScoreRate = storage.paperObj.weakScoreRate;
  //         if (vm.weakScoreRate != 0) {
  //           vm.weakTrainingForm.scoreRateMin =
  //             ((vm.weakScoreRate - 1) * 20) / 100;
  //           vm.weakTrainingForm.scoreRateMax = (vm.weakScoreRate * 20) / 100;
  //         } else {
  //           vm.weakTrainingForm.scoreRateMin = vm.weakRateMin / 100;
  //           vm.weakTrainingForm.scoreRateMax = vm.weakRateMax / 100;
  //         }

  //         //知识点
  //         vm.allPointList = storage.paperObj.allPointList;
  //         vm.pointList = storage.paperObj.pointList;
  //         vm.getClassListAjax();
  //       } else {
  //         vm.errorTrainingForm = storage.paperObj;
  //         vm.errorDateRange = storage.paperObj.errorDateRange;
  //         //得分率
  //         vm.errorRateMin = storage.paperObj.errorRateMin;
  //         vm.errorRateMax = storage.paperObj.errorRateMax;
  //         vm.errorScoreRate = storage.paperObj.errorScoreRate;
  //         if (vm.errorScoreRate != 0) {
  //           vm.errorTrainingForm.scoreRateMin =
  //             ((vm.errorScoreRate - 1) * 20) / 100;
  //           vm.errorTrainingForm.scoreRateMax = (vm.errorScoreRate * 20) / 100;
  //         } else {
  //           vm.errorTrainingForm.scoreRateMin = vm.errorRateMin / 100;
  //           vm.errorTrainingForm.scoreRateMax = vm.errorRateMax / 100;
  //         }

  //         // 考试场次
  //         vm.errorFormPaperIds = storage.paperObj.examPaperIds.split(",");

  //         vm.getClassListAjax();
  //       }
  //     }
  //     // if (to.name != "PaperPreview") {
  //     //   console.log("LearningPaper");
  //     //   removeStore({ name: "" });
  //     // }
  //   });
  // },
  data() {
    return {
      pageTab: pageTab,
      currentTab: 1, //1-错题训练 2-薄弱项训练
      gradeOptions: [],
      classOptions: [],
      //错题
      errorTrainingForm: {
        gradeId: "",
        classNums: "",
        timeRange: "", //时间段
        beginDate: "",
        endDate: "",
        examType: "", //考试类型
        examPaperIds: "", //试卷IDs
        scoreRateMax: 0.2, //最大得分率
        scoreRateMin: 0,
        scoreRateOrder: 0, //得分率排序:0-从低到高 1-从高到低
        questionCount: "", //试题数量
        isNeedOriginal: 0, //训练模式0-错题拓展(变式题) 1-错题重做(原题)
        // proportion: 1, //变式题比例 0-无(isNeedOriginal=1,做原题) 1-1:1 2-1:2 3-1:3 4-1:4 5-1:5
        year: "",
      },
      errorScoreRate: 1, //得分率
      errorRateMin: "", //自定义最小得分率
      errorRateMax: "", //自定义最大得分率
      examPaperLoading: false,
      errorFormPaperIds: [],
      tableData: [],
      //薄弱项

      weakTrainingForm: {
        gradeId: "",
        classNums: "",
        timeRange: 0, //时间段
        beginDate: "",
        endDate: "",
        examType: "", //考试类型
        scoreRateMax: 0.2, //最大得分率
        scoreRateMin: 0,
        scoreRateOrder: 0, //得分率排序:0-从低到高 1-从高到低
        questionCount: "", //试题数量
        isNeedOriginal: 0, //训练模式0-错题拓展(变式题) 1-错题重做(原题)
        proportion: 1, //变式题比例 0-无(isNeedOriginal=1,做原题) 1-1:1 2-1:2 3-1:3 4-1:4 5-1:5
        year: "",
        knowledgeSetting: "",
      },
      weakDateRange: [],
      weakScoreRate: 1, //得分率
      weakRateMin: "", //自定义最小得分率
      weakRateMax: "", //自定义最大得分率
      pointList: [], //页面展示
      allPointList: [],
      pointLoading: false,

      examScopeDialogVisible: false, //选择考试范围
      examTypeOptions: examTypeOptions,
      optionsTime: optionsTime,
      errorDateRange: [],
      examPaperList: [],
      selectAllExam: 1,
      multipleSelection: [],
      paperRows: [],
      showType: true,
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    errorDateRange() {
      this.refreshTimeRange();
    },
    weakDateRange() {
      this.refreshTimeRange();
    },
  },
  created() {
    this.getBaseGradeList();
  },
  methods: {
    showData() {},
    changePageTab(tab_index) {
      if (this.currentTab != tab_index) {
        this.currentTab = tab_index;
      }
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },

    getBaseGradeList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level == this.getLevelId) {
              this.gradeOptions.push(item);
            }
          });
        }
      });
      this.$nextTick(() => {
        this.examTypeOptions.forEach((item) => {
          item.select = false;
        });
      });
    },
    getClassByGradeId(gradeInfo) {
      if (this.currentTab == 1) {
        if (this.errorTrainingForm.gradeId == gradeInfo.id) {
          return;
        }
        this.errorTrainingForm.year = gradeInfo.year;
        this.errorTrainingForm.gradeId = gradeInfo.id;
      } else {
        if (this.weakTrainingForm.gradeId == gradeInfo.id) {
          return;
        }
        this.weakTrainingForm.year = gradeInfo.year;
        this.weakTrainingForm.gradeId = gradeInfo.id;
        this.refreshPoint();
      }
      this.getClassListAjax();
    },
    getClassListAjax() {
      this.classOptions = [];
      let gradeId =
        this.currentTab == 1
          ? this.errorTrainingForm.gradeId
          : this.weakTrainingForm.gradeId;
      classlistbygrade({ gradeId: gradeId }).then((res) => {
        let classNums = res.data.data;
        classNums = classNums.filter((i) => i.name != "重点关注");
        if (classNums && classNums.length > 0) {
          classNums.forEach((item) => {
            this.classOptions.push(item);
          });
        } else {
          this.$message.error("当前年级暂无相关班级");
        }
        this.classOptions.forEach((item) => {
          item.select = false;
        });
        // this.setClassNumByStograge();
      });
    },
    setClassNumByStograge() {
      let _storage = getStore({ name: "createPaper" });
      let classNums = [];
      if (_storage) {
        if (_storage.paperObj.classNums.indexOf(",") > -1) {
          classNums = _storage.paperObj.classNums.split(",");
        } else {
          classNums = [_storage.paperObj.classNums];
        }
      }
      if (classNums.length > 0) {
        classNums.forEach((val) => {
          this.classOptions.forEach((item) => {
            if (item.classNum == val) {
              item.select = true;
            }
          });
        });
      }
    },
    setClassNum(classInfo) {
      let valueArr = [];
      this.classOptions.forEach((val) => {
        if (val.id == classInfo.id) {
          val.select = !val.select;
        }
        if (val.select) {
          valueArr.push(val.classNum);
        }
      });
      this.classOptions = JSON.parse(JSON.stringify(this.classOptions));
      this.currentTab == 1
        ? (this.errorTrainingForm.classNums = valueArr.join(","))
        : (this.weakTrainingForm.classNums = valueArr.join(","));
      if (this.currentTab == 2) {
        this.refreshPoint();
      }
      //如果是错题训练  点击班级后 就默认去请求考试列表
      if (this.currentTab == 1) {
        this.errorTrainingForm.timeRange = 0;
        //第二个值是为了赋值判断 在没有打开弹出层的时候  就开始调用其方法接口  默认添加进去
        this.examTypeBatchSelection(0, 1);
        // currentTab
      }
    },

    showExamScopeDialog() {
      if (this.errorTrainingForm.gradeId > 0) {
        this.examScopeDialogVisible = true;
        // console.log(this.paperRows);
        this.$nextTick(() => {
          if (this.paperRows && this.paperRows.length > 0 && this.showType) {
            // console.log(this.$refs.multipleTable);
            this.showType = false;
            this.paperRows.forEach((row) => {
              this.$refs.multipleTable.toggleRowSelection(row);
            });
          }
        });
        let _storage = getStore({ name: "createPaper" });
        if (_storage && _storage.paperObj.examType) {
          this.setExamTypeByStorage();
        }
      } else {
        this.$message.error("请先确认已选择年级");
      }
    },
    setTimeRange(time) {
      if (this.currentTab == 1) {
        if (time == 99) {
          this.errorTrainingForm.timeRange = 99;
        } else {
          this.errorTrainingForm.beginDate = "";
          this.errorTrainingForm.endDate = "";
          this.errorTrainingForm.timeRange = time.value;
          if (this.errorTrainingForm.examType.length > 0) {
            this.getPapersAjax();
          }
        }
      } else {
        if (time == 99) {
          this.weakTrainingForm.timeRange = 99;
        } else {
          this.weakTrainingForm.beginDate = "";
          this.weakTrainingForm.endDate = "";
          this.weakTrainingForm.timeRange = time.value;
          this.refreshPoint();
        }
      }
    },
    refreshTimeRange() {
      if (this.currentTab == 1) {
        if (this.errorDateRange && this.errorDateRange.length > 0) {
          this.errorTrainingForm.timeRange = 99;
          this.errorTrainingForm.beginDate = this.errorDateRange[0];
          this.errorTrainingForm.endDate = this.errorDateRange[1];
          this.getPapersAjax();
        }
      } else if (this.currentTab == 2) {
        if (this.weakDateRange && this.weakDateRange.length > 0) {
          this.weakTrainingForm.timeRange = 99;
          this.weakTrainingForm.beginDate = this.weakDateRange[0];
          this.weakTrainingForm.endDate = this.weakDateRange[1];
          this.refreshPoint();
        }
      } else {
        if (this.currentTab == 1) {
          this.errorTrainingForm.beginDate = "";
          this.errorTrainingForm.endDate = "";
          // this.getPapersAjax();
        } else {
          this.weakTrainingForm.beginDate = "";
          this.weakTrainingForm.endDate = "";
          // this.refreshPoint();
        }
      }
    },
    examTypeBatchSelection(type, val) {
      //type:0-全选 1-反选 2-取消
      let valueArr = [];
      this.examTypeOptions.forEach((val) => {
        if (type == 0) {
          val.select = true;
        } else if (type == 1) {
          val.select = !val.select;
        } else if (type == 2) {
          val.select = false;
        }
        if (val.select) {
          valueArr.push(val.value);
        }
      });
      let len = valueArr.length;
      if (len > 1) {
        this.errorTrainingForm.examType = valueArr.join(",");
      } else if (len == 1) {
        this.errorTrainingForm.examType = valueArr.toString();
      } else {
        this.errorTrainingForm.examType = "";
      }
      this.getPapersAjax(val);
    },
    setExamTypeByStorage() {
      let _storage = getStore({ name: "createPaper" });
      let examType = [];
      if (_storage) {
        if (_storage.paperObj.examType.indexOf(",") > -1) {
          examType = _storage.paperObj.examType.split(",");
        } else {
          examType = [_storage.paperObj.examType];
        }
      }
      if (examType.length > 0) {
        examType.forEach((val) => {
          this.examTypeOptions.forEach((item) => {
            if (item.value == val) {
              item.select = true;
            }
          });
        });
        this.getPapersAjax();
      }
    },
    setExamType(item) {
      // this.errorTrainingForm.examType = item.value;
      let valueArr = [];
      this.examTypeOptions.forEach((val) => {
        if (val.value == item.value) {
          val.select = !val.select;
        }
        if (val.select) {
          valueArr.push(val.value);
        }
      });
      this.errorTrainingForm.examType = valueArr.join(",");
      this.getPapersAjax();
    },

    getPapers() {
      this.getPapersAjax();
    },
    getPapersAjax(val) {
      let tempObj = {
        beginDate: this.errorTrainingForm.beginDate,
        endDate: this.errorTrainingForm.endDate,
        classNums: this.errorTrainingForm.classNums,
        examType: this.errorTrainingForm.examType, //考试类型
        gradeId: this.errorTrainingForm.gradeId,
        scoreRateMax: this.errorTrainingForm.scoreRateMax, //最大得分率
        scoreRateMin: this.errorTrainingForm.scoreRateMin,
        scoreRateOrder: this.errorTrainingForm.scoreRateOrder,
        timeRange: this.errorTrainingForm.timeRange,
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      };
      this.examPaperLoading = true;
      getExamListByTimeRange(tempObj).then((res) => {
        this.examPaperLoading = false;
        this.examPaperList = res.data.data;
        let paperIds = [];
        this.examPaperList.forEach((item, key) => {
          paperIds.push(item.examPaperId);
          item.index = key + 1;
        });
        //val存在就是沒有調用弹出层
        if (val) {
          this.errorFormPaperIds = paperIds;
          this.errorTrainingForm.examPaperIds = paperIds.join(",");
          // console.log(this.errorFormPaperIds);
          // console.log(this.errorTrainingForm.examPaperIds);
        }
        if (this.errorFormPaperIds.length == 0) {
          // this.selectExamPaperIds();
        } else {
          let rows = [];
          this.paperRows = [];
          this.errorFormPaperIds.forEach((item) => {
            this.examPaperList.forEach((val) => {
              if (item == val.examPaperId) {
                rows.push(val);
              }
            });
          });
          this.showType = true;
          this.paperRows = rows;
          if (!val) {
            this.$nextTick(() => {
              rows.forEach((row) => {
                this.$refs.multipleTable.toggleRowSelection(row);
              });
            });
          }
        }
      });
    },
    handleSelectionChange(val) {
      this.errorFormPaperIds = [];
      this.multipleSelection = val;
      val.forEach((item) => {
        this.errorFormPaperIds.push(item.examPaperId);
      });
    },
    selectExamPaperIds() {
      this.$refs.multipleTable.toggleAllSelection();
    },
    clearExamPaperIds() {
      this.selectAllExam = 0;
      this.errorFormPaperIds = [];
      this.$refs.multipleTable.clearSelection();
    },
    closeExamScopeDialog() {
      this.examScopeDialogVisible = false;
    },
    saveExamScope() {
      this.closeExamScopeDialog();
    },

    setScoreRateOrder(order) {
      this.currentTab == 1
        ? (this.errorTrainingForm.scoreRateOrder = order)
        : (this.weakTrainingForm.scoreRateOrder = order);
      if (this.currentTab == 2) {
        this.refreshPoint();
      }
    },
    setScoreRate(rate) {
      if (this.currentTab == 1) {
        this.errorRateMin = "";
        this.errorRateMax = "";
        this.errorScoreRate = rate;
        this.errorTrainingForm.scoreRateMin = ((rate - 1) * 20) / 100;
        this.errorTrainingForm.scoreRateMax = (rate * 20) / 100;
      } else {
        this.weakRateMin = "";
        this.weakRateMax = "";
        this.weakScoreRate = rate;
        this.weakTrainingForm.scoreRateMin = ((rate - 1) * 20) / 100;
        this.weakTrainingForm.scoreRateMax = (rate * 20) / 100;
        this.refreshPoint();
      }
    },
    customScoreRate() {
      if (this.currentTab == 1) {
        this.errorScoreRate = 0;
      } else {
        this.weakScoreRate = 0;
        this.weakTrainingForm.scoreRateMin = "";
        this.weakTrainingForm.scoreRateMax = "";
        // this.refreshPoint();
        this.allPointList = [];
        this.pointList = [];
      }
    },
    setMinRate() {
      if (this.currentTab == 1) {
        if (this.judgeInputRatioValue(0, this.errorRateMin)) {
          this.errorTrainingForm.scoreRateMin = this.errorRateMin / 100;
        }
      } else {
        if (this.judgeInputRatioValue(0, this.weakRateMin)) {
          this.weakTrainingForm.scoreRateMin = this.weakRateMin / 100;
          if (
            !this.dataIsEmpty(this.weakRateMax) &&
            !this.dataIsEmpty(this.weakRateMin)
          ) {
            this.refreshPoint();
          }
        }
      }
    },
    setMaxRate() {
      if (this.currentTab == 1) {
        if (this.judgeInputRatioValue(1, this.errorRateMax)) {
          this.errorTrainingForm.scoreRateMax = this.errorRateMax / 100;
        }
      } else {
        if (this.judgeInputRatioValue(1, this.weakRateMax)) {
          this.weakTrainingForm.scoreRateMax = this.weakRateMax / 100;
          if (
            !this.dataIsEmpty(this.weakRateMax) &&
            !this.dataIsEmpty(this.weakRateMin)
          ) {
            this.refreshPoint();
          }
        }
      }
    },
    //判断知识点得分率自定义值
    judgeInputRatioValue(type, num) {
      //type:0-min 1-max
      let format = true;
      let rate = parseInt(num);
      //数值大于100直接清空
      if (rate > 100) {
        this.$message.error("最大输入值不能超过100");
        if (type == 0) {
          this.currentTab == 1
            ? (this.errorRateMin = "")
            : (this.weakRateMin = "");
        } else {
          this.currentTab == 1
            ? (this.errorRateMax = "")
            : (this.weakRateMax = "");
        }
        format = false;
        return format;
      }
      /**
       * 数值0-100
       * 最小值不能大于最大值
       * 最大值不能小于最小值
       */
      let min = this.currentTab == 1 ? this.errorRateMin : this.weakRateMin;
      let max = this.currentTab == 1 ? this.errorRateMax : this.weakRateMax;
      if (type == 0) {
        if (!this.dataIsEmpty(max)) {
          if (rate > Number(max)) {
            this.$message.error("最小值不能大于最大值");
            this.currentTab == 1
              ? (this.errorRateMin = "")
              : (this.weakRateMin = "");
            format = false;
            return format;
          }
        }
      } else if (type == 1) {
        if (!this.dataIsEmpty(min)) {
          if (rate < Number(min)) {
            this.$message.error("最大值不能小于最小值");
            this.currentTab == 1
              ? (this.errorRateMax = "")
              : (this.weakRateMax = "");
            format = false;
            return format;
          }
        }
      }
      return format;
    },

    setTrainingMode(mode) {
      if (this.currentTab == 1) {
        this.errorTrainingForm.isNeedOriginal = mode;
        // if (mode == 1) {
        //   this.errorTrainingForm.proportion = 0;
        // }
      } else {
        this.weakTrainingForm.isNeedOriginal = mode;
        // if (mode == 1) {
        //   this.weakTrainingForm.proportion = 0;
        // }
      }
    },
    getPointsAjax() {
      this.pointLoading = true;
      let pointObj = {
        beginDate: this.weakTrainingForm.beginDate,
        classNums: this.weakTrainingForm.classNums,
        endDate: this.weakTrainingForm.endDate,
        examType: this.weakTrainingForm.examType,
        gradeId: this.weakTrainingForm.gradeId,
        levelId: this.getLevelId,
        scoreRateMax: this.weakTrainingForm.scoreRateMax,
        scoreRateMin: this.weakTrainingForm.scoreRateMin,
        scoreRateOrder: this.weakTrainingForm.scoreRateOrder,
        subjectId: this.getSubjectId,
        timeRange: this.weakTrainingForm.timeRange,
      };
      knowledgeList(pointObj)
        .then((res) => {
          this.pointLoading = false;
          this.allPointList = res.data.data;
          this.allPointList.forEach((item) => {
            item.max = "";
          });
          this.loopAddPoint();
        })
        .catch(() => {
          this.pointLoading = false;
        });
    },
    addPoint() {
      if (this.allPointList.length > 0) {
        this.loopAddPoint();
      } else {
        this.getPointsAjax();
      }
    },
    loopAddPoint() {
      let startIndex = this.pointList.length;
      let endIndex =
        startIndex + 5 > this.allPointList.length
          ? this.allPointList.length
          : startIndex + 5;
      if (startIndex == endIndex) {
        this.$message.warning("暂无更多知识点");
        return;
      }
      for (let i = startIndex; i < endIndex; i++) {
        this.pointList.push(this.allPointList[i]);
      }
      this.pointList = JSON.parse(JSON.stringify(this.pointList));
    },
    refreshPoint() {
      this.allPointList = [];
      this.pointList = [];
      this.getPointsAjax();
    },
    resetMaxCount(item, index) {
      // console.log("pointList..", this.pointList);
      if (item.max > item.count) {
        this.$message.error("最大题量不能大于现有题量");
        this.pointList[index].max = "";
        return;
      }
      this.allPointList[index].max = item.max;
      this.pointList[index].max = item.max;
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    fieldsCheck() {
      let validat = true;
      if (this.currentTab == 1) {
        if (this.dataIsEmpty(this.errorTrainingForm.gradeId)) {
          this.$message.error("请确认已选择年级");
          validat = false;
          return validat;
        }
        let len = this.errorFormPaperIds.length;
        if (len < 1) {
          this.$message.error("请确认已选择考试场次");
          validat = false;
          return validat;
        }
        if (this.dataIsEmpty(this.errorTrainingForm.questionCount)) {
          this.$message.error("请确认已填写试题数量");
          validat = false;
          return validat;
        }
      }
      if (this.currentTab == 2) {
        if (this.dataIsEmpty(this.weakTrainingForm.gradeId)) {
          this.$message.error("请确认已选择年级");
          validat = false;
          return validat;
        }
      }
      return validat;
    },
    createPaper() {
      if (!this.fieldsCheck()) {
        return;
      }
      if (this.currentTab == 1) {
        let len = this.errorFormPaperIds.length;
        if (len > 1) {
          this.errorTrainingForm.examPaperIds =
            this.errorFormPaperIds.join(",");
        } else if (len == 1) {
          this.errorTrainingForm.examPaperIds =
            this.errorFormPaperIds.toString();
        }
      }
      let baseForm =
        this.currentTab == 1 ? this.errorTrainingForm : this.weakTrainingForm;
      let errorObj = Object.assign(
        {
          levelId: Number(this.getLevelId),
          subjectId: this.getSubjectId,
        },
        baseForm
      );
      if (this.currentTab == 1) {
        generatePaperError(errorObj).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("生成试卷成功");
            this.toPaperView(res.data.data, this.integrateCacheData());
          }
        });
      } else {
        let point = [];
        this.pointList.forEach((item) => {
          let max = this.dataIsEmpty(item.max) ? 0 : item.max;
          let obj = {
            number: item.number,
            max: max,
            scoreRate: item.scoreRate,
            name: item.name,
          };
          point.push(obj);
        });
        let weakObj = errorObj;
        weakObj.knowledgeSetting = JSON.stringify(point);
        generatePaperPoint(weakObj).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("生成试卷成功");
            this.toPaperView(res.data.data, this.integrateCacheData());
          }
        });
      }
    },
    // 整合缓存数据
    integrateCacheData() {
      let storage = {};
      if (this.currentTab == 1) {
        storage = {
          year: this.errorTrainingForm.year,
          gradeId: this.errorTrainingForm.gradeId,
          classNums: this.errorTrainingForm.classNums,
          //得分率
          errorScoreRate: this.errorScoreRate,
          errorRateMin: this.errorRateMin,
          errorRateMax: this.errorRateMax,
          scoreRateOrder: this.errorTrainingForm.scoreRateOrder,
          questionCount: this.errorTrainingForm.questionCount,
          isNeedOriginal: this.errorTrainingForm.isNeedOriginal,
          // 选择知识点范围
          timeRange: this.errorTrainingForm.timeRange,
          errorDateRange: this.errorDateRange,
          examType: this.errorTrainingForm.examType,
          examPaperIds: this.errorTrainingForm.examPaperIds,
        };
      } else {
        storage = {
          year: this.weakTrainingForm.year,
          gradeId: this.weakTrainingForm.gradeId,
          classNums: this.weakTrainingForm.classNums,
          timeRange: this.weakTrainingForm.timeRange,
          weakDateRange: this.weakDateRange,
          weakScoreRate: this.weakScoreRate,
          weakRateMin: this.weakRateMin,
          weakRateMax: this.weakRateMax,
          scoreRateOrder: this.weakTrainingForm.scoreRateOrder,
          allPointList: this.allPointList,
          pointList: this.pointList,
          isNeedOriginal: this.weakTrainingForm.isNeedOriginal,
        };
      }
      return storage;
    },
    toPaperView(plan_id) {
      //保存页面数据
      // setStore({
      //   name: "createPaper",
      //   content: {
      //     tab_id: this.currentTab,
      //     paperObj: paperObj,
      //   },
      // });
      this.$router.push({
        name: "PaperPreview",
        query: {
          id: plan_id,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.learning-paper {
  // .content {
  //   .error-training {
  .el-input {
    width: 50px;
    padding: 2px 8px;
    border-bottom: 1px solid #e0e0e0;
    margin-right: 4px;
    .el-input__inner {
      border: none;
      padding: 0;
      text-align: center;
      height: unset;
      line-height: unset;
    }
  }
  .el-form {
    .el-form-item {
      margin-bottom: 12px;
      label {
        margin-bottom: unset;
      }
      .el-form-item__label {
        line-height: unset;
        padding: 2px 0;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 12px;
      }
      .el-form-item__content {
        line-height: unset;
        .form-item {
          .el-radio {
            padding: 4px 0;
          }
        }
      }
    }
    .el-form-item__error {
      top: 75%;
    }
    .el-form-item.is-required:not(.is-no-asterisk)
      .el-form-item__label-wrap
      > .el-form-item__label:before,
    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      content: "";
    }
  }
  // }
  // }
  .el-date-editor {
    border: none;
    .el-range__icon,
    .el-range__close-icon {
      display: none;
    }
    .el-range-input {
      background-color: #f5f5f5;
      border-radius: 4px;
    }
    .el-range-separator {
      padding: unset;
      margin: 0 4px;
    }
    .el-input__inner {
      padding: 3px 0px;
    }
  }

  .weak-training {
    .el-date-editor {
      margin-bottom: 12px;
    }
    .el-range-input {
      padding: 4px 0px;
    }
    .el-range-editor.el-input__inner {
      padding: unset;
      line-height: unset;
      height: unset;
    }
  }

  .dialog-exam-scope {
    .el-dialog__header {
      padding: 24px 32px 0;
      .el-dialog__title {
        font-size: 20px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.85);
      }
      .el-dialog__headerbtn {
        .el-dialog__headerbtn .el-dialog__close {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 700;
        }
      }
    }
    .el-dialog__body {
      .el-range-editor.el-input__inner {
        padding: 3px 0;
      }
      .exam-list-item {
        .el-checkbox {
          color: rgba(0, 0, 0, 0.85);
        }
        .list {
          .el-checkbox {
            width: 100%;
          }
          .el-table__header-wrapper {
            .el-checkbox {
              // display: none;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.learning-paper {
  width: 1220px;
  margin: 0 auto 60px;
  padding-top: 40px;
  .tab {
    display: flex;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 2px solid rgba(16, 198, 148, 0.2);
    .on {
      color: #2474ed;
    }
    .tab-item {
      cursor: pointer;
      padding: 10px 16px;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
  .content {
    width: 100%;
    margin-top: 22px;
    padding: 0 20px 10px;
    border-bottom: 1px dashed #e0e0e0;
    // .error-training {
    .el-form {
      // padding: 0 20px 10px;
      .list-item {
        padding: 2px 10px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.85);
        background-color: #f5f5f5;
        cursor: pointer;
        border-bottom: 1px solid transparent;
        margin-bottom: 10px;
        &:not(:last-child) {
          margin-right: 14px;
        }
      }
      .on {
        background-color: #2474ed;
        color: rgba(255, 255, 255, 1);
      }
      .form-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;
        color: rgba(0, 0, 0, 0.85);
        align-items: center;
        .score-rate {
          display: flex;
          align-items: center;
          .line {
            width: 30px;
            height: 2px;
            background-color: #e0e0e0;
            margin: 0 8px;
          }
        }
      }
      .form-btn {
        padding: 2px 0px;
        color: #2474ed;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        width: 150px;
        align-items: center;
        margin-bottom: 12px;
        .el-icon-d-arrow-right {
          margin-left: 4px;
        }
      }
    }
    // }
    .weak-training {
      .label {
        padding: 2px 0;
        color: rgba(0, 0, 0, 0.5);
      }
      .point-list {
        margin: 0 12px;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 12px;
        .points {
          max-height: 210px;
          overflow-y: scroll;
        }
        .point-item {
          padding: 5px 10px;
          display: flex;
          justify-content: space-between;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.04);
          }
          .point-name {
            padding: 5px 10px;
            background-color: #2474ed;
            border-radius: 4px;
            color: #ffffff;
            span {
              &:first-child {
                margin-right: 6px;
              }
            }
          }
          .point-count {
            color: rgba(0, 0, 0, 0.55);
            display: flex;
            align-items: center;
            .plr8 {
              padding: 0 8px;
            }
          }
        }
        .point-add {
          display: flex;
          padding: 5px 10px;
          .btn {
            padding: 5px 40px;
            background-color: #f5f5f5;
            border-radius: 4px;
            color: #2474ed;
            cursor: pointer;
          }
          .el-icon-plus {
            margin-left: 12px;
          }
        }
      }
    }
  }
  .exam-type {
    display: block !important;
  }
  .content-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    align-items: center;
    .btns {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
      .btn-item {
        padding: 0 10px;
        cursor: pointer;
        color: #2474ed;
      }
    }
    .lists {
      display: flex;
      flex-wrap: wrap;
      padding-top: 12px;
    }
    .list-item {
      padding: 2px 10px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.85);
      background-color: #f5f5f5;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-right: 14px;
      }
    }

    .on {
      background-color: #2474ed;
      color: rgba(255, 255, 255, 1);
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .create-paper {
      background-color: #2474ed;
      color: #ffffff;
      padding: 10px 40px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .dialog-exam-scope {
    .dialog-content {
      .exam-list-item {
        background-color: #f5f5f5;
        // height: 140px;
        margin: 0 12px;
        padding: 12px;
        max-height: 300px;
        overflow-y: scroll;
        .top {
          height: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .clear-all {
            margin-left: 20px;
            cursor: pointer;
            &:hover {
              color: #2474ed;
            }
          }
        }
      }
      .exam-tips {
        color: rgba(0, 0, 0, 0.5);
        padding: 8px 12px;
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      .btn {
        padding: 10px 16px;
        color: #ffffff;
        background-color: #2474ed;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
